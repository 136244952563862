import React, { useRef, useState } from 'react';
import './App.css';
import GoogleButton from 'react-google-button'

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyCikUiBEaUklp6v8QlmkZv4pR23waQ1W6M",
  authDomain: "react-chat-f67fd.firebaseapp.com",
  projectId: "react-chat-f67fd",
  storageBucket: "react-chat-f67fd.appspot.com",
  messagingSenderId: "675239315937",
  appId: "1:675239315937:web:18b91db39549fa407aeaa0",
  measurementId: "G-9BTN1N810N"
})

const auth = firebase.auth();
const firestore = firebase.firestore();


function App() {

  const [user] = useAuthState(auth);

  return (
    <div className="App">
      <header>
        <title>Gołomp Chat</title>
        <h1>
          <img src="https://e7.pngegg.com/pngimages/502/486/png-clipart-pigeon-pigeon.png"/>
          <img src="https://i.gremicdn.pl/image/free/7a170d78756f1af66ed4d1c9f48b0f8c/?t=resize:fill:1200:716,enlarge:1"/>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdxSlOq9TqXK4LFCBThTnK0-0CMDkhgWhoHw&usqp=CAU"/>
        </h1>
        <SignOut />
      </header>

      <section>
        {user ? <ChatRoom /> : <SignIn />}
      </section>

    </div>
  );
}

function SignIn() {

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }

  return (
    <>
      <GoogleButton className="sign-in" onClick={signInWithGoogle}>Sign in with Google</GoogleButton>
      <center><p>Do not violate this chat or Kapitan Bomba will find you</p></center>
    </>
  )

}

function SignOut() {
  return auth.currentUser && (
    <button className="sign-out" onClick={() => auth.signOut()}>Sign Out</button>
  )
}


function ChatRoom() {
  const dummy = useRef();
  const messagesRef = firestore.collection('messages'); // choose collection
  const query = messagesRef.orderBy('createdAt').limitToLast(25);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');


  const sendMessage = async (e) => {
    e.preventDefault();

    const { uid, photoURL } = auth.currentUser;

    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL
    })

    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (<>
    <main>

      {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}
      <span ref={dummy}></span>

    </main>

    <form onSubmit={sendMessage}>

      <input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="weź coś napisz" />

      <button type="submit" disabled={!formValue}>🕊️</button>

    </form>
  </>)
}


function ChatMessage(props) {
  const { text, uid, photoURL } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

  return (<>
    <div className={`message ${messageClass}`}>
      <img src={photoURL || 'https://api.adorable.io/avatars/23/abott@adorable.png'} />
      <p>{text}</p>
    </div>
  </>)
}


export default App;